import validate from "/var/www/stage/qst/node_modules/nuxt/dist/pages/runtime/validate.js";
import host_45redirect_45global from "/var/www/stage/qst/middleware/hostRedirect.global.js";
import trailing_45slash_45global from "/var/www/stage/qst/middleware/trailing-slash.global.js";
import manifest_45route_45rule from "/var/www/stage/qst/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  host_45redirect_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "trailing-slash-redirect": () => import("/var/www/stage/qst/middleware/trailing-slash-redirect.js")
}